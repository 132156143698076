@use "./colours" as colours;

// Remove annoying 5px margin
body, html {
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

// Icons inside buttons should not have margin by default
button mat-icon {
  margin: 0;
}

@mixin icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
}

.ezc-screen-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 20px;
  margin-left: -12px;

  h2 {
    margin: 0;
  }

  mat-icon {
    @include icon-size(22px);

    border: 1px solid colours.$color-text;
    border-radius: 50%;
  }
}
