@use 'sass:math';
@use "sass:map";
@use "sass:color";
@use '@angular/material' as mat;
@use "./fonts" as fonts;
@use "./colours" as colours;
@include mat.core;

// Include this once, and only once, in your application
@include mat.core;

/**
Here we define a configuration for Angular Material.
We have been followed these guides:
https://material.angular.io/guide/theming
https://material.angular.io/guide/typography
But everything should be summarised in README.md :)

Make sure to skip the step "Including font assets", since we are doing that
in "fonts.scss".
 */

//////////////////////////////////////////////////////////////////
// Colour palette creation functions
// Inspired by: https://lsparagino.medium.com/sass-only-material-angular-palette-generator-35dd406a6b81
//////////////////////////////////////////////////////////////////
$values: (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, a100, a200, a400, a700);

@function create-palette($color) {
  $white: #fff;
  $black: #000;
  $base-dark: multiply($color, $color);
  $palette: (
    50 : color.mix($color, $white, 12%),
    100 : color.mix($color, $white, 30%),
    200 : color.mix($color, $white, 50%),
    300 : color.mix($color, $white, 70%),
    400 : color.mix($color, $white, 85%),
    500 : color.mix($color, $white, 100%),
    600 : color.mix($color, $base-dark, 87%),
    700 : color.mix($color, $base-dark, 70%),
    800 : color.mix($color, $base-dark, 54%),
    900 : color.mix($color, $base-dark, 25%),
    a100 : color.adjust(saturate(color.mix($black, $base-dark, 15%), 80%), $lightness: 65%),
    a200 : color.adjust(saturate(color.mix($black, $base-dark, 15%), 80%), $lightness: 55%),
    a400 : color.adjust(saturate(color.mix($black, $base-dark, 15%), 100%), $lightness: 45%),
    a700 : color.adjust(saturate(color.mix($black, $base-dark, 15%), 100%), $lightness: 40%)
  );
  $contrast: ();

  @each $v in $values {
    $contrast: map.merge($contrast, ($v: get-contrast(map_get($palette, $v))))
  }

  $palette: map.merge($palette, (contrast: $contrast));

  @return $palette;
}


@function multiply($rgb1, $rgb2) {
  $r: math.floor(calc(color.red($rgb1) * color.red($rgb2) / 255));
  $g: math.floor(calc(color.green($rgb1) * color.green($rgb2) / 255));
  $b: math.floor(calc(color.blue($rgb1) * color.blue($rgb2) / 255));

  @return rgb($r, $g, $b);
}

@function get-brightness($color) {
  @return calc((color.red($color) * 299 + color.green($color) * 587 + color.blue($color) * 114) / 1000);
}

@function is-light($color) {
  @return get-brightness($color) >= 128;
}

@function get-contrast($color) {
  @if is-light($color) {
    @return #000;
  } @else {
    @return #fff;
  }
}


//////////////////////////////////////////////////////////////////
// Colour palette schemes - Primary & Secondary are mandatory
//////////////////////////////////////////////////////////////////
$primary-scheme: create-palette(colours.$color-primary);
$secondary-scheme: create-palette(colours.$color-accent);
$error-scheme: create-palette(colours.$color-warn);


//////////////////////////////////////////////////////////////////
// Colour palette definitions
//////////////////////////////////////////////////////////////////

$primary-palette: mat.m2-define-palette($primary-scheme, 500);
$secondary-palette: mat.m2-define-palette($secondary-scheme, 500);
$error-palette: mat.m2-define-palette($error-scheme, 500);


//////////////////////////////////////////////////////////////////
// Typography configuration
// All CSS class mapping: https://material.angular.io/guide/typography#typography-levels
//////////////////////////////////////////////////////////////////

$typography: mat.m2-define-typography-config(
  // Same font as the one defined in fonts.scss
  $font-family: 'Avenir Next',
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(45px, 48px, 400),
  $headline-6: mat.m2-define-typography-level(32px, 38px, 600),
  $subtitle-1: mat.m2-define-typography-level(24px, 31.2px, 600),
  $subtitle-2: mat.m2-define-typography-level(18px, 21px, 400),
  $body-1: mat.m2-define-typography-level(18px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(14px, 18px, 400),
  $button: mat.m2-define-typography-level(17px, 23px, 600)
);


//////////////////////////////////////////////////////////////////
// Theme - Requires colour palettes and typography
//////////////////////////////////////////////////////////////////

$light-theme: mat.m2-define-light-theme((
  color: (
    // Previously defined palettes
    primary: $primary-palette,
    accent: $secondary-palette,
    warn: $error-palette
  ),
  // Previously defined typography
  typography: $typography,
  // Default density. To know more about it, read this:
  // https://material.angular.io/guide/theming#customizing-density
  density: 0
));


//////////////////////////////////////////////////////////////////
// Final implementation - Make the application use the config
//////////////////////////////////////////////////////////////////

@include mat.all-component-themes($light-theme);
@include mat.typography-hierarchy($typography);